<template>
  <div class="c-authList">
    <a-tree
      v-if="rCtrl"
      :defaultExpandedKeys="defaultExpandedKeys"
      :checkedKeys="checkedKeys"
      :tree-data="dataSource"
      @check="onCheck"
      checkable
      :checkStrictly="true"
    >
      <template #title="{ dataRef }">
        <div>
          <a-tag color="green" v-if="dataRef.menuType === 'M'">目录</a-tag>
          <a-tag color="blue" v-if="dataRef.menuType === 'C'">菜单</a-tag>
          <a-tag color="purple" v-if="dataRef.menuType === 'A'">按钮</a-tag>
          {{ dataRef.title }}
        </div>
      </template>
    </a-tree>
  </div>
</template>
<script>
import treeUtils from '@/utils/treeUtils'
export default {
  props: {
    value: {
      type: Array,
      require: true,
      default: () => {
        return []
      },
    },
    dataSource: {
      type: Array,
      require: true,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      checkedKeys: {
        checked: [],
      },
      defaultExpandedKeys: [],
      rCtrl: true,
    }
  },
  methods: {
    onCheck(v, e) {
      let checkedKeys = [...this.checkedKeys.checked]
      const currentCheckKey = e.node.dataRef.key
      const parents = treeUtils.findAllParents(this.dataSource, (node) => node.key === currentCheckKey)
      const parentsKeys = parents.map((node) => node.key)
      const children = treeUtils.findAllChildren(this.dataSource, (node) => node.key === currentCheckKey)
      const childrenKeys = children.map((node) => node.key)
      // 正选,选中所有子，所有父，自身
      if (!e.node.checked) {
        checkedKeys = [...checkedKeys, ...parentsKeys, ...childrenKeys, currentCheckKey]
        // 反选，取消所有子节点，判断父节点是否包含lastNode
      } else {
        // 节点是否能成为最下级的树节点
        const removeKeys = [...childrenKeys, currentCheckKey]
        checkedKeys = checkedKeys.filter((k) => !removeKeys.includes(k))
        const isTreeLastNode = (node) => {
          return node.menuType === 'C' || node.menuType === 'A'
        }
        parents.forEach((parent) => {
          // 当前parent是否存在可以保留的最下级节点
          const canRetainNode =
            isTreeLastNode(parent) ||
            !!treeUtils.find(parent.children, (node) => checkedKeys.includes(node.key) && isTreeLastNode(node))

          // 没有，就从checkedKeys里面删除
          if (!canRetainNode) {
            const removeIndex = checkedKeys.findIndex((k) => k === parent.key)
            checkedKeys.splice(removeIndex, 1)
          }
        })
      }
      const checkedKeysIncludesParents = Array.from(new Set(checkedKeys))
      this.$emit('input', checkedKeysIncludesParents)
    },
    refresh() {
      this.rCtrl = false
      this.$nextTick(() => {
        this.rCtrl = true
      })
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          const checked = [...val]
          let defaultExpandedKeys = []
          treeUtils.forEach(this.dataSource, (node) => {
            if (val.includes(node.key)) {
              const isLeaf = node.children ? node.children.length === 0 : true
              if (!isLeaf) {
                defaultExpandedKeys.push(node.key)
              }
            }
          })
          // 已全选的则不需要展开
          defaultExpandedKeys = defaultExpandedKeys.filter((key) => {
            const allChildren = treeUtils.findAllChildren(this.dataSource, (node) => node.key === key)
            const notSelectAllChildren = allChildren.some((node) => {
              const isLeaf = node.children ? node.children.length === 0 : true
              if (isLeaf) {
                if (!checked.includes(node.key)) {
                  return true
                }
              }
            })
            return notSelectAllChildren
          })
          // 为0时默认展开第一级
          this.defaultExpandedKeys = defaultExpandedKeys.length ? defaultExpandedKeys : [this.dataSource[0].key]
          this.checkedKeys = {
            checked: checked,
          }
        }
      },
      immediate: true,
    },
    dataSource: {
      handler() {
        this.refresh()
      },
      immediate: true,
    },
  },
}
</script>
<style lang="less" scoped>
.c-authList {
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 32px;
  margin: 4px 0;
}
</style>
